import { MenuItem } from "./menu.model";

export const ROOTMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },


  // {
  //   id: 3,
  //   label: "MENUITEMS.DASHBOARDS.TEXT",
  //   icon: "bx bxs-dashboard",
  //   link: "/dashboard",
  // },

  {
    id: 2,
    label: "Home",
    icon: "bx bxs-home",
    link: "/patients",

  },

  {
    id: 3,
    label: "Patients",
    icon: "bx bx-plus-medical",
    subItems: [
      {
        id: 301,
        label: "Add Patient",
        link: "/addPatient",
        parentId: 5,
      },
    ],
  },

  {
    id: 4,
    label: "MENUITEMS.DATA_MANAGEMENT.TEXT",
    link: "super_admin",
    icon: "bx bxs-data",
    subItems: [
      {
        id: 401,
        label: "Adverse Events",
        link: "super_admin/adverseEvents",
        parentId: 9,
      },
      {
        id: 402,
        label: "Biochemical Markers",
        link: "/super_admin/biochemical_markers",
        parentId: 9,
      },
      {
        id: 403,
        label: "BiologicalTherapy Drug",
        link: "/super_admin/biologicalTherapyDrug",
        parentId: 9,
      },
      {
        id: 404,
        label: "Cancer Site, Location , Morphology & Grade",
        link: "/super_admin/cancer_site",
        parentId: 9,
      },
      {
        id: 405,
        label: "Chemotherapy Drug",
        link: "/super_admin/chemotherapyDrug",
        parentId: 9,
      },
      {
        id: 406,
        label: "Comorbidities",
        link: "/super_admin/diseases",
        parentId: 9,
      },
      {
        id: 407,
        label: "Diagnostic Tests",
        link: "/super_admin/diagnostic_tests",
        parentId: 9,
      },
      {
        id: 408,
        label: "Diet",
        link: "/super_admin/diet",
        parentId: 9,
      },
      {
        id: 409,
        label: "Doctor",
        link: "/super_admin/doctor_dm",
        parentId: 9,
      },
      {
        id: 410,
        label: "Drugs",
        link: "/super_admin/drugs",
        parentId: 9,
      },
      {
        id: 411,
        label: "Family History Relationship",
        link: "/super_admin/family_history_relation_types",
        parentId: 9,
      },
      {
        id: 412,
        label: "Gene",
        link: "/super_admin/gene",
        parentId: 9,
      },
      {
        id: 413,
        label: "HormonalTherapy Drug",
        link: "/super_admin/hormonalTherapyDrug",
        parentId: 9,
      },
      {
        id: 414,
        label: "Hospitals",
        link: "/super_admin/hospitals",
        parentId: 9,
      },
      {
        id: 415,
        label: "Imaging Site, Location",
        link: "/super_admin/imagingSite",
        parentId: 9,
      },
      {
        id: 416,
        label: "Immunosuppressions",
        link: "/super_admin/immunosuppressions",
        parentId: 9,
      },
      {
        id: 417,
        label: "Language",
        link: "/super_admin/language",
        parentId: 9,
      },
      {
        id: 418,
        label: "Laterality",
        link: "/super_admin/laterality",
        parentId: 9,
      },
      {
        id: 419,
        label: "LymphNode Groups",
        link: "/super_admin/lymphNodeGroups",
        parentId: 9,
      },
      {
        id: 420,
        label: "Molecular Marker & Method",
        link: "/super_admin/molecularMarker",
        parentId: 9,
      },
      {
        id: 421,
        label: "Ngs Test Names",
        link: "/super_admin/ngs_test_package_names",
        parentId: 9,
      },
      {
        id: 422,
        label: "Occupation",
        link: "/super_admin/occupation",
        parentId: 9,
      },
      {
        id: 423,
        label: "Pathways Involved",
        link: "/super_admin/pathways_Involved",
        parentId: 9,
      },
      {
        id: 424,
        label: "Procedure Diagnostic",
        link: "/super_admin/procedureDiagnostic",
        parentId: 9,
      },
      {
        id: 425,
        label: "Radiations",
        link: "/super_admin/radiations",
        parentId: 9,
      },
      {
        id: 426,
        label: "Regimen",
        link: "/super_admin/regimens",
        parentId: 9,
      },
      {
        id: 427,
        label: "Relation Types",
        link: "/super_admin/relation_types",
        parentId: 9,
      },
      {
        id: 428,
        label: "Religion",
        link: "/super_admin/religion",
        parentId: 9,
      },
      {
        id: 429,
        label: "Recipients",
        link: "/super_admin/sales Persons",
        parentId: 9,
      },
      {
        id: 430,
        label: "Serum Biomarker Test",
        link: "/super_admin/serum_biomarker_test",
        parentId: 9,
      },
      {
        id: 431,
        label: "Surgeries",
        link: "/super_admin/surgeries",
        parentId: 9,
      },
      {
        id: 432,
        label: "Tobacco",
        link: "/super_admin/specifyOtherFormsOfTobacco",
        parentId: 9,
      },
    ],
  },
  {
    id: 5,
    label: "Employee Management",
    icon: "bx bxs-group",
    link: "/admin/employee-list",

  },
];

export const ADMINMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },


  // {
  //   id: 3,
  //   label: "MENUITEMS.DASHBOARDS.TEXT",
  //   icon: "bx bxs-dashboard",
  //   link: "/dashboard",
  // },

  {
    id: 2,
    label: "Home",
    icon: "bx bxs-home",
    link: "/patients",

  },

  {
    id: 5,
    label: "Patients",
    icon: "bx bx-plus-medical",
    subItems: [
      // {
      //   id: 6,
      //   label: "Patients",
      //   link: "/patients",
      //   parentId: 5,
      // },
      {
        id: 7,
        label: "Add Patient",
        link: "/addPatient",
        parentId: 5,
      },
      // {
      //   id: 8,
      //   label: "Edit Patient",
      //   link: "/editPatient",
      //   parentId: 5,
      // },
    ],
  },


  {
    id: 2,
    label: "Employee Management",
    icon: "bx bxs-group",
    link: "/admin/employee-list",

  },
];
export const labMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "MENUITEMS.DATA_MANAGEMENT.TEXT",
    link: "super_admin",
    icon: "bx bxs-data",
    subItems: [
      {
        id: 23,
        label: "Lab Packages",
        link: "/super_admin/lab_packages",
        parentId: 9,
      },
      {
        id: 24,
        label: "Lab Test Process",
        link: "/super_admin/lab_tests_process",
        parentId: 9,
      },
      {
        id: 25,
        label: "Lab Test",
        link: "/super_admin/lab_tests",
        parentId: 9,
      },
      {
        id: 26,
        label: "Lab Test Status Email",
        link: "/super_admin/lab_test_status_email",
        parentId: 9,
      },
      {
        id: 27,
        label: "Recipients",
        link: "/super_admin/sales Persons",
        parentId: 9,
      },
      // {
      //   id: 279,
      //   label: "Doctor",
      //   link: "/super_admin/doctor_dm",
      //   parentId: 9,
      // },
    ]
  }
];

export const bioinfomaticianMenu: MenuItem[] = [

  {
    id: 23,
    label: "",
    link: "",
    parentId: 1,
  },

      {
        id: 24,
        label: "DNA Details",
        link: "/bioinformatician/dna_details",
        parentId: 1,
      },
      {
        id: 23,
        label: "RNA Details",
        link: "/bioinformatician/rna_details",
        parentId: 2,
      },
];
export const USERMENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },

  {
    id: 2,
    label: "Home",
    icon: "bx bxs-home",
    link: "/patients",

  },

  {
    id: 5,
    label: "Patients",
    icon: "bx bx-plus-medical",
    subItems: [
      {
        id: 7,
        label: "Add Patient",
        link: "/addPatient",
        parentId: 5,
      }
    ]
  }
];

export const PROFILEMENU: MenuItem[] = [
  {
    id: 36,
    label: "Patients",
    link: "/patients",
    icon: "bx-arrow-back",
  },

  // /patients/profile

  // {
  //   id: 35,
  //   label: "Patients",
  //   icon: "bx-home-circle",
  //   link: "/patients",
  //   subItems: [
  //     {
  //       id: 36,
  //       label: "Profile",
  //       link: "/patients/profile",
  //       parentId: 35,
  //     },

  //     {
  //       id: 101,
  //       label: "Edit Medical",
  //       link: "/patients/editMedical",
  //       parentId: 35,
  //     },

  //     {
  //       id: 1001,
  //       label: "Edit Patient",
  //       link: "/patients/editPatient",
  //       parentId: 35,
  //     },

  //     // {
  //     //   id: 36,
  //     //   label: "Patients",
  //     //   link: "/patients",
  //     //   parentId: 35,
  //     // },
  //     // {
  //     //   id: 37,
  //     //   label: "Add Patient",
  //     //   link: "/addPatient",
  //     //   parentId: 35,
  //     // },
  //     {
  //       id: 38,
  //       label: "Edit Patient",
  //       link: "/editPatient",
  //       parentId: 35,
  //     },
  //   ],
  // },

  // {
  //   id: 39,
  //   label: "Medical",
  //   icon: "bx-home-circle",
  //   subItems: [
  //     {
  //       id: 40,
  //       label: "Edit Patient",
  //       link: "/editMedical",
  //       parentId: 39,
  //     },
  //     {
  //       id: 85,
  //       label: "Edit Patient",
  //       link: "/editPatient",
  //       parentId: 39,
  //     },
  //   ],
  // },
  {
    id: 35,
    label: "Profile",
    icon: "bx-home-circle",
    link: "/eventInfo",
  },
  {
    id: 36,
    label: "Edit Patient",
    icon: "bx-home-circle",
    link: "/eventInfo",
  },
  {
    id: 101,
    label: "Edit Medical",
    icon: "bx-home-circle",
    link: "/eventInfo",
  },
  {
    id: 41,
    label: "Events List",
    icon: "bx-home-circle",
    link: "/eventInfo",
  },

  {
    id: 1,
    label: "Event",
    isTitle: true,
  },

  {
    id: 42,
    label: "Treatment",
    link: "/treatments",
    icon: "bx-home-circle",
    subItems: [
      {
        id: 49,
        label: "Ablation",
        link: "/ablation",
        parentId: 42,
      },

      {
        id: 43,
        label: "Biological Therapy",
        link: "/treatments/biologicalTherapy",
        parentId: 42,
      },


      {
        id: 44,
        label: "Chemotherapy",
        link: "/chemotherapy",
        parentId: 42,
      },
      {
        id: 45,
        label: "Hormonal Therapy",
        link: "/hormonalTherapy",
        parentId: 42,
        subItems: [
          {
            id: 60,
            label: "Drug Therapy",
            link: "/treatments/hormonalTherapy/drugTherapy",
            parentId: 45,
          },
          {
            id: 61,
            label: "Suregery",
            link: "/treatments/hormonalTherapy/suregery",
            parentId: 45,
          },
          {
            id: 62,
            link: "/treatments/hormonalTherapy/radiation",
            label: "Radiation",
            parentId: 45,
          },
        ],
      },

      {
        id: 46,
        label: "Radiation",
        link: "/radiation",
        parentId: 42,
      },
      {
        id: 47,
        label: "Supportive Care",
        link: "/supportiveCare",
        parentId: 42,
      },

      {
        id: 48,
        label: "Surgery",
        link: "/surgery",
        parentId: 42,
      },

    ],
  },

  {
    id: 99,
    label: "Diagnostics",
    link: "/diagnostic",
    icon: "bx-home-circle",
    subItems: [
      {
        id: 95,
        label: "Molecular Pathology",
        link: "diagnostic/molecularPathology",
        parentId: 99,
      },

      {
        id: 94,
        label: "Pathology",
        link: "diagnostic/pathology",
        parentId: 99,
      },

      {
        id: 93,
        label: "Staging Information",
        link: "diagnostic/stagingInformation",
        parentId: 99,
      },

      {
        id: 92,
        label: "Serum Biomarker",
        link: "diagnostic/serumBiomarker",
        parentId: 99,
      },

      // {
      //   id: 99,
      //   label: "Surgery",
      //   link: "diagnostic/surgery",
      //   parentId: 99,
      // },
      {
        id: 199,
        label: "Procedure",
        link: "diagnostic/procedure",
        parentId: 199,
      },
      {
        id: 91,
        label: "Hematology",
        link: "diagnostic/hematology",
        parentId: 99,
      },
      {
        id: 50,
        label: "Cell Work",
        link: "/cellWork",
        parentId: 42,
      },
      {
        id: 90,
        label: "Clinical Biochemistry",
        link: "diagnostic/clinicalBiochemistry",
        parentId: 99,
      },

      {
        id: 98,
        label: "Imaging Sites",
        link: "/imagingSites",
        parentId: 99,
        subItems: [
          {
            id: 102,
            label: "Bone Scan",
            link: "diagnostic/imagingSites/BONE_SCAN",
            parentId: 98,
          },
          {
            id: 103,
            label: "Bronchoscopy",
            link: "diagnostic/imagingSites/BRONCHOSCOPY",
            parentId: 98,
          },
          {
            id: 104,
            label: "Colonoscopy",
            link: "diagnostic/imagingSites/COLONOSCOPY",
            parentId: 98,
          },
          {
            id: 105,
            label: "CT Scan",
            link: "diagnostic/imagingSites/CT",
            parentId: 98,
          },
          {
            id: 106,
            label: "Dopa PET MRI",
            link: "diagnostic/imagingSites/DOPA_PET_MRI",
            parentId: 98,
          },
          {
            id: 107,
            label: "Doppler Test",
            link: "diagnostic/imagingSites/DOPPLER_TEST",
            parentId: 98,
          },
          {
            id: 108,
            label: "ECG",
            link: "diagnostic/imagingSites/ECG",
            parentId: 98,
          },
          {
            id: 109,
            label: "ECHO",
            link: "diagnostic/imagingSites/ECHO",
            parentId: 98,
          },
          {
            id: 110,
            label: "Endoscopy",
            link: "diagnostic/imagingSites/ENDOSCOPY",
            parentId: 98,
          },
          {
            id: 111,
            label: "ERCP",
            link: "diagnostic/imagingSites/ERCP",
            parentId: 98,
          },
          {
            id: 112,
            label: "Gallium Scan",
            link: "diagnostic/imagingSites/GALLIUM_SCAN",
            parentId: 98,
          },
          {
            id: 113,
            label: "Gastroscopy",
            link: "diagnostic/imagingSites/GASTROSCOPY",
            parentId: 98,
          },
          {
            id: 114,
            label: "Laryngoscopy",
            link: "diagnostic/imagingSites/LARYNGOSCOPY",
            parentId: 98,
          },
          {
            id: 115,
            label: "Mammography",
            link: "diagnostic/imagingSites/MAMMOGRAPHY",
            parentId: 98,
          },
          {
            id: 116,
            label: "MRCP",
            link: "diagnostic/imagingSites/MRCP",
            parentId: 98,
          },
          {
            id: 117,
            label: "MRI",
            link: "diagnostic/imagingSites/MRI",
            parentId: 98,
          },
          {
            id: 118,
            label: "PET CT",
            link: "diagnostic/imagingSites/PET_CT",
            parentId: 98,
          },
          {
            id: 119,
            label: "Scintigraphy",
            link: "diagnostic/imagingSites/SCINTIGRAPHY",
            parentId: 98,
          },
          {
            id: 120,
            label: "Sigmoidoscopy",
            link: "diagnostic/imagingSites/SIGMOIDOSCOPY",
            parentId: 98,
          },
          {
            id: 121,
            label: "Thyroid Scan",
            link: "diagnostic/imagingSites/THYROID_SCAN",
            parentId: 98,
          },
          {
            id: 122,
            label: "TRUS",
            link: "diagnostic/imagingSites/TRUS",
            parentId: 98,
          },
          {
            id: 123,
            label: "Upper GI",
            link: "diagnostic/imagingSites/UPPER_GI",
            parentId: 98,
          },
          {
            id: 124,
            label: "USG",
            link: "diagnostic/imagingSites/USG",
            parentId: 98,
          },
          {
            id: 125,
            label: "X-ray",
            link: "diagnostic/imagingSites/X_RAY",
            parentId: 98,
          },
        ],
      },
    ],
  },

  {
    id: 88,
    label: "Clinical Notes",
    link: "diagnostic/consultation",
    icon: "bx-home-circle",
  },

  {
    id: 87,
    label: "Response Evaluation",
    link: "diagnostic/performanceStatus",
    icon: "bx-home-circle",
  },
];


// Level 2 Main Menu
export const ROOTMENULevel2: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "Home",
    icon: "bx bxs-home",
    link: "/patients",

  },

  {
    id: 3,
    label: "Patients",
    icon: "bx bx-plus-medical",
    subItems: [
      {
        id: 301,
        label: "Add Patient",
        link: "/addPatient",
        parentId: 5,
      },
    ],
  },

];

// Level 3 4 and 5 Main Menu
export const ROOTMENULevel345: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 2,
    label: "Home",
    icon: "bx bxs-home",
    link: "/patients",

  },

  ];
  export const QCMenu: MenuItem[] = [
    {
      id: 1,
      label: "MENUITEMS.MENU.TEXT",
      isTitle: true,
    },
    {
      id: 2,
      label: "Home",
      icon: "bx bxs-home",
      link: "/qc/patients_list",

    },

  ];

