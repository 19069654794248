// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//  apiUrl: "http://localhost:8085",
  //  apiUrl: "http://52.66.98.251:9511",
  apiUrl: "https://www.v2.api.ecrf.4basecare.co.in",


  AWS_ACCESS_KEY_ID: 'AKIASLG5BKZA6QBEB2MU',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'ap-south-1',

  defaultauth: "fackbackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
